import { defaultDateFormat, ROLES } from "constants/settings";
import dayjs from "dayjs";

export const getPeriod = (startDate, endDate) => {
    const start = dayjs(startDate).format(defaultDateFormat);
    const end = dayjs(endDate).format(defaultDateFormat);

    return start === end ? start : `${start} - ${end}`;
};

export const baseAdminURL = process.env.REACT_APP_SERVER_BASE_URL;

export const checkForAdmin = (role) => role === ROLES.admin;
export const checkForUser = (role) => role === ROLES.user;
export const checkForGuest = (role) => role === ROLES.guest;
