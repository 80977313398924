import { fetchBaseQuery, createApi } from "@reduxjs/toolkit/query/react";
import Snack from "components/Snack/Snack";
import store from "store";
import { logOut, setRefreshToken, setToken } from "store/auth/authSlice";
import Logger from "utils/logger";
import { baseAdminURL } from "utils/utils";
import { Mutex } from "async-mutex";
import messages from "constants/messages";

// https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#automatic-retries

const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
    baseUrl: baseAdminURL,
    prepareHeaders: (headers, { getState }) => {
        const token = getState().auth.token;
        const uid = getState().auth?.user?.uid;
        if (token) {
            headers.set("authorization", `Bearer ${token}`);
        }
        if (uid) headers.set("uid", uid);
        return headers;
    }
});

const firebaseFetch = async () => {
    try {
        const response = await fetch(
            `https://securetoken.googleapis.com/v1/token?key=${process.env.REACT_APP_FIREBASE_API_KEY}`,
            {
                method: "POST",
                body: JSON.stringify({
                    grant_type: "refresh_token",
                    refresh_token: store.getState().auth.refreshToken
                })
            }
        );
        const result = await response.json();
        const prepareResult = {
            token: result.access_token,
            refreshToken: result.refresh_token
        };
        return prepareResult;
    } catch (err) {
        Logger.error(err);
        Snack.error(messages.general.snack.firebaseError);
    }
};

const baseQueryWithReauth = async (args, api, extraOptions) => {
    // wait until the mutex is available without locking it
    await mutex.waitForUnlock();

    let result = await baseQuery(args, api, extraOptions);

    if (result?.error?.status === 401) {
        // checking whether the mutex is locked
        if (!mutex.isLocked()) {
            const release = await mutex.acquire();
            try {
                const refreshResult = await firebaseFetch();

                if (refreshResult?.token) {
                    // store the new token
                    api.dispatch(setToken({ token: refreshResult.token }));
                    api.dispatch(
                        setRefreshToken({
                            refreshToken: refreshResult.refreshToken
                        })
                    );
                    // retry the original query with new access token
                    result = await baseQuery(args, api, extraOptions);
                } else {
                    Snack.error(messages.general.snack.code401); // TODO error
                    api.dispatch(logOut());
                }
            } finally {
                // release must be called once the mutex should be released again.
                release();
            }
        } else {
            // wait until the mutex is available without locking it
            await mutex.waitForUnlock();
            result = await baseQuery(args, api, extraOptions);
        }
    } else if (result?.error?.status === 403) {
        Snack.error(messages.general.snack.code403);
        api.dispatch(logOut());
    }

    return result;
};

export const apiSlice = createApi({
    baseQuery: baseQueryWithReauth,
    endpoints: (build) => ({})
});
