import React, { useEffect } from "react";

import { Box } from "@mui/material";
import "./login.scss";

import ParticleLogin from "components/Particles/ParticleLogin";
import Login from "./Login";

// import PropTypes from "prop-types";

const LoginPage = () => {
    useEffect(() => {
        document.body.className = "login";
    }, []);
    return (
        <Box className="login-page">
            {/* Don't use a Particle and a component that update something on the same level, as this will cause the animation to update */}
            <ParticleLogin />
            <Login />
        </Box>
    );
};

LoginPage.propTypes = {};

export default LoginPage;
