import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
    name: "auth",
    initialState: { user: null, token: null, refreshToken: null },
    reducers: {
        setToken: (state, { payload }) => {
            state.token = payload.token;
        },
        setUser: (state, { payload }) => {
            state.user = payload.user;
        },
        setRefreshToken: (state, { payload }) => {
            state.refreshToken = payload.refreshToken;
        },
        logOut: (state) => {
            state.user = null;
            state.token = null;
            state.refreshToken = null;
        }
    }
});

export const { setRefreshToken, logOut, setToken, setUser } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state) => state.auth.user;
export const selectCurrentToken = (state) => state.auth.token;
export const selectCurrentRefreshToken = (state) => state.auth.refreshToken;
