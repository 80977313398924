import React from "react";
import Particles from "react-tsparticles";
import settings from "./tunnel.json";

import { loadFull } from "tsparticles";

const ParticleLogin = () => {
    const particlesInit = async (main) => {
        await loadFull(main);
    };

    return (
        <Particles
            className="login__particle"
            id="tsparticles"
            init={particlesInit}
            options={settings}
        />
    );
};

export default ParticleLogin;
