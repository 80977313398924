import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { Checkbox, DialogActions, FormControlLabel, Grid } from "@mui/material";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import WarningIcon from "@mui/icons-material/Warning";
import classNames from "classnames";

import "./dialog.scss";
import messages from "constants/messages";

const CustomizedDialogs = ({
    title,
    isPushing,
    isDisabled,
    onCancel,
    onSave,
    children,
    saveText,
    cancelText,
    type,
    isConfirmDisabled,
    isNonClose,
    nonCloseTitle,
    ...props
}) => {
    const [NonCloseStatus, setNonCloseStatus] = useState(false);
    return (
        <Dialog {...props}>
            <DialogTitle
                className={classNames("dialog__title", {
                    delete: type === "delete"
                })}
            >
                {type === "delete" && <WarningIcon fontSize="large" />}
                <span>{title}</span>
                {onCancel ? (
                    <IconButton
                        aria-label="Close"
                        className="dialog__close"
                        onClick={onCancel}
                    >
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </DialogTitle>

            <DialogContent dividers className="dialog__content">
                {children}
            </DialogContent>

            <DialogActions className="dialog__actions">
                <Grid container justifyContent="center" alignItems="center">
                    <Grid item>
                        {isNonClose && (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={NonCloseStatus}
                                        onChange={() => {
                                            setNonCloseStatus(!NonCloseStatus);
                                        }}
                                        name="isNonClose"
                                        color="primary"
                                    />
                                }
                                label={nonCloseTitle}
                            />
                        )}
                    </Grid>
                    <Grid item className="dialog__buttons">
                        <Button
                            onClick={onCancel}
                            color="secondary"
                            className="dialog__button cancel"
                        >
                            {cancelText}
                        </Button>
                        {onSave && !isConfirmDisabled && (
                            <Button
                                variant="contained"
                                disabled={isDisabled || isPushing}
                                onClick={() => {
                                    onSave(NonCloseStatus);
                                }}
                                color={type === "delete" ? "error" : "success"}
                                className={classNames("dialog__button", {
                                    delete: type === "delete",
                                    save: type !== "delete"
                                })}
                            >
                                {isPushing
                                    ? messages.general.buttons.loading
                                    : saveText}
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};

CustomizedDialogs.propTypes = {
    cancelText: PropTypes.string,
    saveText: PropTypes.string,
    title: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    children: PropTypes.any.isRequired,
    isPushing: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isConfirmDisabled: PropTypes.bool,
    type: PropTypes.string,
    nonCloseTitle: PropTypes.string,
    isNonClose: PropTypes.bool
};

CustomizedDialogs.defaultProps = {
    saveText: "Save",
    cancelText: "Cancel",
    isPushing: false,
    isDisabled: false,
    isNonClose: false,
    nonCloseTitle: "Continue"
};

export default CustomizedDialogs;
