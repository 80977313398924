import {
    Button,
    Checkbox,
    FormLabel,
    TextField,
    Typography
} from "@mui/material";
import SelectorWithCheckbox from "components/Form/Selectors/SelectorWithCheckbox";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import AddEventSchema from "./AddEventSchema";
// import PropTypes from "prop-types";

import LoadingComponent from "components/Loadings/LoadingComponent";

import bg from "assets/images/collage/bg-nature.jpg";
import { DateObject } from "react-multi-date-picker";
import messages from "constants/messages";
// import Calculator from "components/Calculators/Calculator";
import CheckBoxWrapper from "components/Form/CustomBox/CheckBoxWrapper";
import { useGetUsersQuery } from "store/users/userApiSlice";
import {
    useAddEventMutation,
    useLazyGetSingleEventQuery,
    useUpdateEventMutation
} from "store/events/eventApiSlice";
import Logger from "utils/logger";
import Snack from "components/Snack/Snack";
import classNames from "classnames";
import DatePickerMui from "components/DatePickers/DatePickerMui";
// import { isEqual } from "lodash";

// TODO https://trello.com/c/FZiF6joE

const SingleEvent = () => {
    const { state } = useLocation();
    const { id } = useParams();
    const { data: userList } = useGetUsersQuery();
    const [getSingleEvent, { data: singleEvent, isLoading }] =
        useLazyGetSingleEventQuery();
    const { user } = useSelector((state) => state.auth);
    // Mutation
    const [addEvent] = useAddEventMutation();
    const [updateEvent] = useUpdateEventMutation();

    // const nextDay = new Date().setDate(new Date().getDate() + 1);
    const nextYear = new Date().setFullYear(new Date().getFullYear() + 1);

    const defaultValues = {
        name: "",
        location: "",
        members: [],
        list: [],
        isCreateChat: false,
        dateEvent: Date.now(),
        author: user.id,
        whoLastUpdated: user.id
    };

    // State
    const [completed, setCompleted] = useState();
    const [initialValues, setInitialValues] = useState(defaultValues);
    // const [formValues, setFormValues] = useState(defaultValues);
    // const [isDirty, setIsDirty] = useState(false);

    const isEdit = state?.edit || false;
    const singleModeCalendar = true;
    // const usersList = list.map((item) => {
    //     return {
    //         id: item.id,
    //         name: item.name
    //     }
    // })
    const navigate = useNavigate();

    useEffect(() => {
        id && getSingleEvent(id);
    }, [id]);

    useEffect(() => {
        singleEvent && setCompleted(singleEvent.completed);
    }, [singleEvent]);

    const getDateFormat = () => {
        if (singleModeCalendar) {
            return singleEvent?.dateEvent;
        } else {
            return (
                singleEvent?.dateEvent?.map((item) => new DateObject(item)) ||
                []
            ); // the initial value must be an array of objects
        }
    };

    useEffect(() => {
        if (singleEvent && userList) {
            const filteredMembers = [];
            userList.forEach((userItem) => {
                singleEvent.members?.forEach(
                    (memberItem) =>
                        userItem.id === memberItem &&
                        filteredMembers.push(userItem)
                );
            });

            setInitialValues({
                ...defaultValues,
                ...singleEvent,
                members: filteredMembers,
                dateEvent: getDateFormat()
            });
        }
        // singleEvent &&
        //     setFormValues({
        //         ...defaultValues,
        //         ...singleEvent,
        //         dateEvent:
        //             singleEvent?.dateEvent?.map(
        //                 (item) => new DateObject(item)
        //             ) || [] // the initial value must be an array of objects
        //     });
    }, [singleEvent, userList]);

    // useEffect(() => {
    //     console.log(formValues);
    //     // setIsDirty(isEqual(formValues, initialValues));
    // }, [formValues]);

    const handleSubmit = async (values, { resetForm }) => {
        if (completed) return;

        try {
            if (isEdit) {
                await updateEvent({ id, event: values }).unwrap();
                Snack.success(messages.event.snack.update);
            } else {
                await addEvent(values).unwrap();
                Snack.success(messages.event.snack.create);
            }
            resetForm({});
            navigate(-1);
        } catch (err) {
            Logger.error(err);
            Snack.error(messages.event.snack.error);
        }
    };

    const handleEnd = async () => {
        // TODO add dialog modal confirm
        try {
            await updateEvent({
                id,
                event: {
                    ...singleEvent,
                    completed: true,
                    whoLastUpdated: user.id
                }
            }).unwrap();
            Snack.success(messages.event.snack.completed);
            navigate(-1);
        } catch (err) {
            Logger.error(err);
            Snack.error(messages.event.snack.error);
        }
    };

    const handleBack = (dirty) => {
        if (dirty) {
            navigate(-1);
        } else {
            navigate(-1);
        }
    };

    const handleUpdate = (e, setFieldValue) => {
        const { value, id, checked } = e.target || e;

        let newValue = value;
        if (id === "isCreateChat") {
            newValue = checked;
        }
        // setFormValues({
        //     ...formValues,
        //     [id]: newValue
        // });
        setFieldValue(id, newValue);
    };

    return (
        <div className="page-singleevent">
            <div className="event__wrapper">
                <div
                    className={classNames("event__image", {
                        completed
                    })}
                    style={{
                        backgroundImage: `url(${process.env.REACT_APP_PUBLIC_URL}${bg})`
                    }}
                ></div>
            </div>
            <LoadingComponent loading={isLoading} minTime={300}>
                <Formik
                    initialValues={initialValues}
                    validationSchema={AddEventSchema}
                    enableReinitialize={true}
                    onSubmit={handleSubmit}
                    validateOnBlur={false}
                    validateOnChange={true}
                >
                    {({
                        values,
                        errors,
                        touched,
                        setFieldValue,
                        isSubmitting,
                        dirty
                    }) => (
                        <Form autoComplete="off" className="form__wrapper">
                            <TextField
                                className="form__field input"
                                fullWidth
                                variant="filled"
                                id="name"
                                name="name"
                                label={messages.event.labels.name}
                                value={values.name}
                                autoFocus={!isEdit}
                                onChange={(e) => handleUpdate(e, setFieldValue)}
                                error={touched.name && Boolean(errors.name)}
                                helperText={touched.name && errors.name}
                                disabled={completed}
                            />
                            <TextField
                                className="form__field input"
                                fullWidth
                                variant="filled"
                                id="location"
                                name="location"
                                label={messages.event.labels.location}
                                value={values.location}
                                onChange={(e) => handleUpdate(e, setFieldValue)}
                                error={
                                    touched.location && Boolean(errors.location)
                                }
                                helperText={touched.location && errors.location}
                                disabled={completed}
                            />
                            <SelectorWithCheckbox
                                label={messages.event.labels.members}
                                className="form__field"
                                name="members"
                                id="members"
                                options={userList}
                                defaultValue={values?.members}
                                fullWidth
                                variant="filled"
                                onChange={(e) => handleUpdate(e, setFieldValue)}
                                // isSubmitting={isSubmitting}
                                error={
                                    touched.members && Boolean(errors.members)
                                }
                                disabled={completed}
                            />
                            <DatePickerMui
                                className="form__field"
                                name="dateEvent"
                                value={values.dateEvent}
                                singleMode={singleModeCalendar}
                                onChange={(e) => handleUpdate(e, setFieldValue)}
                                label={messages.event.labels.date}
                                minDate={Date.now()}
                                maxDate={nextYear}
                                error={
                                    touched.dateEvent &&
                                    Boolean(errors.dateEvent)
                                }
                                disabled={completed}
                                disablePast
                            />
                            <CheckBoxWrapper
                                fullWidth
                                className="full1"
                                disabled={completed}
                            >
                                <FormLabel
                                    component="legend"
                                    className="form__checkbox"
                                >
                                    <Typography
                                        variant="body1"
                                        component="span"
                                        className={classNames("text", {
                                            disabled: completed
                                        })}
                                    >
                                        {messages.event.labels.isCreateChat}
                                    </Typography>
                                    <Checkbox
                                        aria-label={
                                            messages.event.labels.isCreateChat
                                        }
                                        id="isCreateChat"
                                        name="isCreateChat"
                                        checked={values.isCreateChat}
                                        onChange={(e) =>
                                            handleUpdate(e, setFieldValue)
                                        }
                                        size="medium"
                                    />
                                </FormLabel>
                            </CheckBoxWrapper>
                            {/* <Calculator /> */}
                            <div className="form__box-button full2">
                                <Button
                                    className="form__button"
                                    color="info"
                                    variant="text"
                                    type="button"
                                    onClick={() => handleBack()}
                                >
                                    {messages.general.buttons.back}
                                </Button>
                                <Button
                                    className="form__button"
                                    color="success"
                                    variant="contained"
                                    fullWidth
                                    type="submit"
                                    disabled={
                                        isSubmitting ||
                                        completed ||
                                        (isEdit && !dirty)
                                    }
                                >
                                    {isEdit
                                        ? messages.general.buttons.update
                                        : messages.general.buttons.create}
                                </Button>
                            </div>
                            {isEdit && !completed && (
                                <div className="form__box-button full3">
                                    <Button
                                        className="form__button"
                                        color="error"
                                        variant="outlined"
                                        type="button"
                                        onClick={handleEnd}
                                    >
                                        {messages.general.buttons.setEnd}
                                    </Button>
                                </div>
                            )}
                        </Form>
                    )}
                </Formik>
            </LoadingComponent>
        </div>
    );
};

SingleEvent.propTypes = {};

export default SingleEvent;
