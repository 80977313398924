import { Maintenance } from "components/Maintenance/Maintenance";
import React from "react";
// import PropTypes from "prop-types";

const Movies = (props) => {
    return <Maintenance />;
};

Movies.propTypes = {};

export default Movies;
