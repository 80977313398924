import React from "react";
import DialogEditItem from "components/Dialog/DialogEditItem";
import { Form, Formik } from "formik";
import BasicSelector from "components/Form/Selectors/BasicSelector";
import { ROLES } from "constants/settings";

import PropTypes from "prop-types";
import { Button, TextField } from "@mui/material";
import messages from "constants/messages";
import Logger from "utils/logger";
import Snack from "components/Snack/Snack";
import { useUpdateUserByAdminMutation } from "store/users/userApiSlice";
import UserUpdateSchema from "./UserUpdateSchema";

const UpdateUserDialog = ({ openModal, setOpenModal, initialValues }) => {
    // const [dirtyForm, setDirtyForm] = useState(false);

    const [updateUserByAdmin] = useUpdateUserByAdminMutation();

    const optionsRoles = [];

    Object.keys(ROLES).forEach((value, index) =>
        optionsRoles.push({ id: index, value })
    );

    const handleSubmit = async (values) => {
        try {
            const newValues = {
                nickname: values.nickname || "",
                role: values.role
            };

            await updateUserByAdmin({
                uid: values.uid,
                user: newValues
            }).unwrap();

            Snack.success(messages.admin.snack.update);
            setOpenModal(false);
        } catch (err) {
            Logger.error(err);
            Snack.error(messages.admin.snack.error);
        }
    };

    return (
        <DialogEditItem
            title={messages.admin.forms.title(initialValues.email)}
            modal={openModal}
            showModal={setOpenModal}
            // isDisabled={dirtyForm}
            // onConfirm={handleSend}
            isConfirmDisabled
            cancelText={messages.general.buttons.cancel}
        >
            <Formik
                initialValues={initialValues}
                validationSchema={UserUpdateSchema}
                enableReinitialize={true}
                onSubmit={handleSubmit}
                validateOnBlur={false}
                validateOnChange={true}
            >
                {({
                    values,
                    errors,
                    touched,
                    setFieldValue,
                    isSubmitting,
                    dirty
                }) => (
                    <Form autoComplete="off" className="form__wrapper">
                        <TextField
                            className="form__field input"
                            fullWidth
                            variant="filled"
                            id="email"
                            name="email"
                            label={messages.admin.forms.email}
                            value={values.email}
                            // onChange={(e) => handleUpdate(e, setFieldValue)}
                            error={touched.email && Boolean(errors.email)}
                            helperText={touched.email && errors.email}
                            disabled
                        />
                        <TextField
                            className="form__field input"
                            fullWidth
                            variant="filled"
                            id="name"
                            name="name"
                            label={messages.admin.forms.name}
                            value={values.name}
                            // onChange={(e) => handleUpdate(e, setFieldValue)}
                            error={touched.name && Boolean(errors.name)}
                            helperText={touched.name && errors.name}
                            disabled
                        />
                        <TextField
                            className="form__field input"
                            fullWidth
                            variant="filled"
                            id="nickname"
                            name="nickname"
                            label={messages.admin.forms.nickname}
                            value={values.nickname || ""}
                            onChange={(e) =>
                                setFieldValue("nickname", e.target.value)
                            }
                            error={touched.nickname && Boolean(errors.nickname)}
                            helperText={touched.nickname && errors.nickname}
                        />
                        <BasicSelector
                            className="form__field select"
                            fullWidth
                            variant="filled"
                            id="role"
                            name="role"
                            label={messages.admin.forms.role}
                            value={values.role}
                            options={optionsRoles}
                            onChange={(value) => setFieldValue("role", value)}
                            error={touched.role && Boolean(errors.role)}
                            helperText={touched.role && errors.role}
                        />
                        <div className="form__buttons">
                            <Button
                                className="form__button"
                                color="success"
                                variant="contained"
                                fullWidth
                                type="submit"
                                disabled={isSubmitting || !dirty}
                            >
                                {messages.general.buttons.update}
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </DialogEditItem>
    );
};

UpdateUserDialog.propTypes = {
    openModal: PropTypes.bool,
    setOpenModal: PropTypes.func,
    initialValues: PropTypes.object
};

export default UpdateUserDialog;
