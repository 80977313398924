import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import AccountMenu from "./AccountMenu";
import { useSelector } from "react-redux";

import "./header.scss";
import { Button, IconButton, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link } from "react-router-dom";
import routes from "constants/routes";
import AnimateLogo from "./logo/AnimateLogo";
import messages from "constants/messages";
import { checkForAdmin } from "utils/utils";

const Header = ({ setToggleSedebar, toggleSidebar, isOutsidePage }) => {
    const {
        user: { role }
    } = useSelector((state) => state.auth);
    const [scroll, setScroll] = useState(false);

    const isAdmin = checkForAdmin(role);

    const handleTogglerSidebar = () => {
        setToggleSedebar(!toggleSidebar);
    };

    useEffect(() => {
        const listener = () => {
            setScroll(window.scrollY > 10);
        };

        window.addEventListener("scroll", listener);

        return () => window.removeEventListener("scroll", listener);
    }, []);

    return (
        <AppBar
            className={classNames("header__wrapper", {
                fullsize: !toggleSidebar,
                "fixed-header": !scroll,
                "free-header": scroll
            })}
        >
            <Toolbar sx={{ justifyContent: "space-between" }}>
                {isOutsidePage ? (
                    <>
                        <div className="header__logo">
                            <Typography
                                variant="h6"
                                component="div"
                                sx={{ flexGrow: 1 }}
                            >
                                <Link
                                    className="header__title-link"
                                    to={routes.dashboard}
                                >
                                    <AnimateLogo />
                                </Link>
                            </Typography>
                        </div>
                        <div>
                            <AccountMenu isOutsidePage />
                        </div>
                    </>
                ) : (
                    <>
                        <IconButton
                            onClick={handleTogglerSidebar}
                            size="large"
                            edge="start"
                            color="primary"
                            aria-label="menu"
                            sx={{ mr: 2 }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <div className="header__setting">
                            {isAdmin && (
                                <Button
                                    component={Link}
                                    to={routes.admin}
                                    className="header__admin-link"
                                    color="success"
                                    variant="text"
                                >
                                    {messages.admin.buttons.goToAdmin}
                                </Button>
                            )}
                            <AccountMenu />
                        </div>
                    </>
                )}
            </Toolbar>
        </AppBar>
    );
};

Header.propTypes = {
    setToggleSedebar: PropTypes.func,
    toggleSidebar: PropTypes.bool,
    isOutsidePage: PropTypes.bool
};

export default Header;
