import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import routes from "constants/routes";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

// Level 2
export const PrivateRoute = ({ children }) => {
    const { user, token } = useSelector((state) => state.auth);
    const location = useLocation();

    if (!token || !user) {
        return <Navigate to={routes.login} state={{ from: location }} />;
    }

    return children;
};

PrivateRoute.propTypes = {
    children: PropTypes.object
};

export const AuthRoute = ({ children }) => {
    const { user, token } = useSelector((state) => state.auth);

    if (token && user) {
        return <Navigate to={routes.dashboard} />;
    }

    return children;
};

AuthRoute.propTypes = {
    children: PropTypes.object
};
