import { Maintenance } from "components/Maintenance/Maintenance";
import React from "react";
// import PropTypes from "prop-types";

const Settings = (props) => {
    return <Maintenance />;
};

Settings.propTypes = {};

export default Settings;
