import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
// import PropTypes from "prop-types";

import fakeData from "./fakeData.json";
import "./dashboard.scss";
import BasicCard from "components/Cards/BasicCard";
// import DiscordCard from "components/Cards/discordCard/DiscordCard";

const Dashboard = (props) => {
    return (
        <Box className="dashboard">
            {/* <Grid
        container
        direction="row"
        justifyContent="space-around"
        alignItems="center"
        className="general-info__wrapper"
      >
        <DiscordCard />
      </Grid> */}
            <Grid
                container
                direction="row"
                justifyContent="space-around"
                alignItems="center"
                className="features__wrapper"
            >
                {fakeData.features.map((item) => (
                    <BasicCard key={item.id} data={item} />
                ))}
            </Grid>
        </Box>
    );
};

Dashboard.propTypes = {};

export default Dashboard;
