import React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";

const BasicCard = ({ data }) => {
    const { name, description, subTittle, subDescription, btnText } = data;
    return (
        <Card
            sx={{ minWidth: 275 }}
            variant="primary"
            elevation={4}
            className="features__container"
        >
            <CardContent className="features__paper" color="white">
                {subTittle && (
                    <Typography
                        sx={{ fontSize: 14 }}
                        color="text.secondary"
                        gutterBottom
                    >
                        {subTittle}
                    </Typography>
                )}
                <Typography
                    variant="h5"
                    component="div"
                    className="features__heading"
                >
                    {name}
                </Typography>
                {subDescription && (
                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                        {subDescription}
                    </Typography>
                )}
                {description && (
                    <Typography
                        variant="body2"
                        color="text.secondary"
                        className="features__description"
                    >
                        {description}
                    </Typography>
                )}
            </CardContent>
            <CardActions>
                <Button size="small">{btnText}</Button>
            </CardActions>
        </Card>
    );
};

BasicCard.propTypes = {
    data: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string.isRequired,
        description: PropTypes.string,
        subTittle: PropTypes.string,
        subDescription: PropTypes.string,
        btnText: PropTypes.string
    })
};
export default BasicCard;
