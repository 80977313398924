import { apiSlice } from "api/apiSlice";

// const apiWithTag = apiSlice.enhanceEndpoints({ addTagTypes: ["Users"] });

export const userApiSlice = apiSlice.injectEndpoints({
    endpoints: (build) => ({
        getUsers: build.query({
            query: () => ({
                url: "users"
            }),
            providesTags: ["Users"]
        }),
        // TODO check if removed in authApiSlice
        getUser: build.query({
            query: (uid) => ({
                url: `user/${uid}`
            }),
            providesTags: ["User"]
        }),
        // Update with admin permission
        updateUserByAdmin: build.mutation({
            query: ({ uid, user }) => ({
                url: `admin/user/${uid}`,
                method: "PUT",
                body: user
            }),
            invalidatesTags: ["Users"]
        }),
        updateUser: build.mutation({
            query: ({ uid, user }) => ({
                url: `user/${uid}`,
                method: "PUT",
                body: user
            }),
            invalidatesTags: ["User"]
        })
    })
});

export const {
    useGetUsersQuery,
    useUpdateUserMutation,
    useUpdateUserByAdminMutation,
    useGetUserQuery,
    useLazyGetUserQuery
} = userApiSlice;
