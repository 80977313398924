import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "../Sidebar/Sidebar";
import Header from "../Header/Header";

import "./layout.scss";
import classNames from "classnames";
import { Container } from "@mui/system";
import Breadcrumb from "components/Breadcrumb/Breadcrumb";

import { ErrorBoundary } from "react-error-boundary";
import { ErrorFallback } from "components/Error/ErrorFallbacks";

const Layout = () => {
    const [toggleSidebar, setToggleSedebar] = useState(true);

    return (
        <div className="dashboard-layout">
            <div className="dashboard-layout__main">
                <div
                    className={classNames("dashboard-layout__sidebar", {
                        hidden: !toggleSidebar
                    })}
                >
                    <Sidebar />
                </div>
                <div
                    className={classNames("dashboard-layout__container", {
                        fullsize: !toggleSidebar
                    })}
                >
                    <Header
                        setToggleSedebar={setToggleSedebar}
                        toggleSidebar={toggleSidebar}
                    />
                    <div className="dashboard-layout__content">
                        <Container maxWidth="xl">
                            <ErrorBoundary FallbackComponent={ErrorFallback}>
                                <Breadcrumb />
                                <Outlet />
                            </ErrorBoundary>
                        </Container>
                    </div>
                </div>
            </div>

            {/* <Footer /> */}
        </div>
    );
};

Layout.propTypes = {};

export default Layout;
