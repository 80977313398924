import React from "react";

import TableSticky from "components/Tables/TableSticky";
import { Button } from "@mui/material";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { Link } from "react-router-dom";
import routes from "constants/routes";
// import PropTypes from "prop-types";

import "./event.scss";
import bg from "assets/images/collage/bg-nature.jpg";
import LoadingComponent from "components/Loadings/LoadingComponent";
import messages from "constants/messages";
import { useGetEventsQuery } from "store/events/eventApiSlice";

const EventList = () => {
    const { data: eventList, isLoading } = useGetEventsQuery();

    return (
        <div className="page-events">
            <div className="events__wrapper">
                <div className="events__controls">
                    <div
                        className="controls__image"
                        style={{
                            backgroundImage: `url(${process.env.REACT_APP_PUBLIC_URL}${bg})`
                        }}
                    ></div>
                    <div className="controls__description">
                        <p>{messages.events.title}</p>
                    </div>
                    <div className="controls__actions">
                        <Button
                            color="success"
                            variant="contained"
                            disableElevation
                            component={Link}
                            to={routes.events.add.link}
                        >
                            <AddRoundedIcon /> {messages.events.addButton}
                        </Button>
                    </div>
                </div>
                <div className="events__list">
                    <LoadingComponent loading={isLoading}>
                        <TableSticky events={eventList} />
                    </LoadingComponent>
                </div>
            </div>
        </div>
    );
};

EventList.propTypes = {};

export default EventList;
