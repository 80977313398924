export default {
    general: {
        buttons: {
            create: "Створити",
            add: "Додати",
            remove: "Видалити",
            update: "Оновити",
            edit: "Редагувати",
            back: "Назад",
            setEnd: "Завершити подію",
            cancel: "Відмінити",
            loading: "Зачекайте..."
        },
        snack: {
            firebaseError: "Сервіс оновлення токену не відповідає...",
            code401: "Помилка авторизації",
            code403: "Вам відмовлено в доступі",
            changeRole: "Ваші права доступу було змінено"
        }
    },
    events: {
        title: "Запланував подію? У цьому розділі ти можеш створити подію, запросити людей до неї, скласти список потрібних речей.",
        addButton: "Створити подію",
        emptyList: "Подій ще не створено.",
        table: {
            name: "Назва",
            location: "Локація",
            members: "Люди",
            date: "Дата проведення",
            chat: "Телеграм чат",
            completed: "Подія завершена"
        }
    },
    event: {
        labels: {
            name: "Назва події *",
            location: "Місце проведення події",
            members: "Запрошені люди",
            date: "Дата проведення події",
            isCreateChat: "Створити телеграм чат для події?"
        },
        snack: {
            create: "Подію створено!",
            update: "Подію оновлено!",
            delete: "Подію видалено!",
            completed: "Подію завершено!",
            error: "Щось пішло не так!"
        }
    },
    admin: {
        buttons: {
            goToAdmin: "Admin"
        },
        forms: {
            title: (email) => `Інформація про ${email}`,
            email: "Email",
            name: "Name",
            role: "Role",
            nickname: "Nickname"
        },
        snack: {
            create: "Юзера створено!",
            update: "Юзера оновлено!",
            delete: "Юзера видалено!",
            error: "Щось пішло не так!"
        }
    }
};
