import { initializeApp } from "firebase/app";
import store from "store";
import {
    getAuth,
    GoogleAuthProvider,
    signInWithPopup,
    signOut
} from "firebase/auth";
import Logger from "./logger";
import { setRefreshToken } from "store/auth/authSlice";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID
};

// Initialize Firebase
initializeApp(firebaseConfig);

export const auth = getAuth();
const provider = new GoogleAuthProvider();

provider.setCustomParameters({ prompt: "select_account" });

export const logoutFirebase = () => {
    signOut(auth);
};

// export const logOutFiribase = (redirect = true) => {
//     Session.setStatus();
//     Session.logout(redirect);
// };

export const signInWithGoogle = () => {
    return signInWithPopup(auth, provider)
        .then((response) => {
            const { user } = response;
            store.dispatch(
                setRefreshToken({
                    refreshToken: user.stsTokenManager.refreshToken
                })
            );
            Logger.log(user);
            return user;
        })
        .catch((err) => Logger.error(err));
};
