import {
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography
} from "@mui/material";

import React from "react";
import { Link, NavLink } from "react-router-dom";
import "./sidebar.scss";
import routes from "constants/routes";
import { Menu } from "constants/Menu";
import AnimateLogo from "components/Header/logo/AnimateLogo";

export const NestedList = () => {
    return Menu.map((item) => (
        <ListItemButton
            className="sidebar__button"
            key={item.name}
            component={NavLink}
            to={{
                pathname: item.url,
                state: { from: location }
            }}
        >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText>{item.name}</ListItemText>
        </ListItemButton>
    ));
};

const Sidebar = () => {
    return (
        <div className="sidebar">
            <div className="sidebar__logo">
                <Typography
                    className="sidebar__title"
                    variant="h6"
                    component="div"
                    sx={{ flexGrow: 1 }}
                >
                    <Link className="sidebar__title-link" to={routes.dashboard}>
                        <AnimateLogo />
                    </Link>
                </Typography>
            </div>
            <List
                sx={{
                    width: "100%",
                    maxWidth: 360,
                    bgcolor: "background.paper"
                }}
                component="nav"
                aria-labelledby="nested-list-subheader"
                className="sidebar__list"
                // subheader={
                //   <ListSubheader component="div" id="nested-list-subheader">
                //     Nested List Items
                //   </ListSubheader>
                // }
            >
                <NestedList />
            </List>
        </div>
    );
};

export default Sidebar;
