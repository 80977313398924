import React from "react";
import EditRoundedIcon from "@mui/icons-material/EditRounded";

import PropTypes from "prop-types";
const DynamicBreadcrumb = ({ location }) => (
    <span style={{ display: "flex", alignItems: "center" }}>
        {location?.state?.breadcrumb || (
            <EditRoundedIcon style={{ width: "20px", height: "20px" }} />
        )}
    </span>
);

export const breadcrumbRoutes = [
    {
        path: "/",
        breadcrumb: "Dashboard"
    },
    {
        path: "/events/:id",
        breadcrumb: DynamicBreadcrumb
    }
];

DynamicBreadcrumb.propTypes = {
    location: PropTypes.object,
    match: PropTypes.object
};
