const routes = {
    home: "/*",
    dashboard: "/",
    error: "*",
    login: "login",
    admin: "adminpanel",
    profile: {
        root: "profile",
        settings: "profile/settings"
    },
    news: "news",
    photos: "photos",
    catalogs: {
        root: "catalogs",
        movies: "catalogs/movies",
        books: "catalogs/books",
        add: (path) => `${path}/add`
    },
    events: {
        root: "events",
        edit: {
            root: (id) => `${id}`,
            link: "events/:id"
        },
        add: {
            root: "events/add",
            link: "add",
            path: (path) => `${path}/add`
        }
    },
    calendar: "calendar",
    donate: "donate",
    outside: {
        game: "game"
    }
};

export default routes;
