import React from "react";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import "dayjs/locale/uk";
import Stack from "@mui/material/Stack";

import PropTypes from "prop-types";
import "./datePickerMui.scss";

const DatePickerMui = ({
    name,
    value,
    onChange,
    label,
    minDate,
    maxDate,
    className,
    error,
    disabled,
    disablePast
}) => {
    return (
        <Stack spacing={3}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="uk">
                <DateTimePicker
                    name={name}
                    id={name}
                    className={`datepicker ${className}`}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="filled"
                            error={error}
                            helperText={error ? "error" : null}
                        />
                    )}
                    label={label}
                    value={value}
                    onChange={(newValue) => {
                        onChange({ id: name, value: newValue });
                    }}
                    disabled={disabled}
                    disablePast={disablePast}
                    minDate={dayjs(minDate)}
                    maxDate={dayjs(maxDate)}
                />
            </LocalizationProvider>
        </Stack>
    );
};

DatePickerMui.defaultProps = {};

DatePickerMui.propTypes = {
    value: PropTypes.any,
    name: PropTypes.string,
    label: PropTypes.string,
    minDate: PropTypes.number,
    maxDate: PropTypes.number,
    className: PropTypes.string,
    onChange: PropTypes.func,
    error: PropTypes.bool,
    disabled: PropTypes.bool,
    disablePast: PropTypes.bool
};

export default DatePickerMui;
