import React from "react";
import PropTypes from "prop-types";

import { CircularProgress, Grid } from "@mui/material";

import Dialog from "./Dialog";

const DialogEditItem = ({
    title,
    modal,
    loading,
    showModal,
    onConfirm,
    onCancel,
    children,
    saveText,
    cancelText,
    isConfirmDisabled,
    isDisabled
}) => {
    return (
        <div>
            <Dialog
                type="edit"
                open={modal}
                maxWidth="md"
                fullWidth
                title={title}
                saveText={saveText}
                cancelText={cancelText}
                isConfirmDisabled={isConfirmDisabled}
                isDisabled={isDisabled}
                onCancel={() => {
                    if (onCancel) onCancel();
                    showModal(false);
                }}
                onSave={() => {
                    if (onConfirm) onConfirm();
                }}
            >
                <Grid container direction="column">
                    {loading ? (
                        <Grid className="loading-content">
                            <CircularProgress />
                        </Grid>
                    ) : (
                        <Grid item className="cancel-popup-content">
                            {children}
                        </Grid>
                    )}
                </Grid>
            </Dialog>
        </div>
    );
};

DialogEditItem.propTypes = {
    title: PropTypes.string.isRequired,
    modal: PropTypes.bool.isRequired,
    showModal: PropTypes.func.isRequired,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
    children: PropTypes.any,
    saveText: PropTypes.string,
    loading: PropTypes.bool,
    cancelText: PropTypes.string,
    isConfirmDisabled: PropTypes.bool,
    isDisabled: PropTypes.bool
};

export default DialogEditItem;
