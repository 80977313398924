import React from "react";
import Layout from "components/Layout/Layout";
import { OutsideLayout } from "components/OutsideLayout/OutsideLayout";
import routes from "constants/routes";
import { Route, Routes } from "react-router-dom";
import PropTypes from "prop-types";

import Calendar from "pages/calendar/Calendar";
import Books from "pages/catalogs/books/Books";
import Movies from "pages/catalogs/movies/Movies";
import Dashboard from "pages/dashboard/Dashboard";
import Donate from "pages/donate/Donate";
import ErrorPage from "pages/error/ErrorPage";
import News from "pages/news/News";
import Photos from "pages/photos/Photos";
import Profile from "pages/profile/Profile";
import Settings from "pages/settings/Settings";
import SingleEvent from "pages/events/SingleEvent";
import EventList from "pages/events/EventList";
import AdminPanel from "pages/admin/AdminPanel";

// Level 3
const DashboardRouter = ({ isAdmin }) => {
    return (
        <Routes>
            <Route path={routes.dashboard} element={<Layout />}>
                <Route index element={<Dashboard />} />
                <Route path={routes.profile.root} element={<Profile />} />
                <Route path={routes.profile.settings} element={<Settings />} />
                <Route path={routes.catalogs.movies} element={<Movies />} />
                <Route path={routes.catalogs.books} element={<Books />} />
                <Route path={routes.news} element={<News />} />
                <Route path={routes.photos} element={<Photos />} />
                <Route path={routes.events.root} element={<EventList />} />
                <Route
                    path={routes.events.edit.link}
                    element={<SingleEvent />}
                />
                <Route
                    path={routes.events.add.root}
                    element={<SingleEvent />}
                />
                <Route path={routes.calendar} element={<Calendar />} />
                <Route path={routes.donate} element={<Donate />} />
                {isAdmin && (
                    <Route path={routes.admin} element={<AdminPanel />} />
                )}
            </Route>
            <Route path={routes.dashboard} element={<OutsideLayout />}>
                <Route path={routes.error} element={<ErrorPage />} />
            </Route>
        </Routes>
    );
};

DashboardRouter.propTypes = {
    isAdmin: PropTypes.bool
};

export default DashboardRouter;
