import React from "react";
// import PropTypes from "prop-types";
import "./donate.scss";

const Donate = (props) => {
    return (
        <div className="donate-page">
            <div className="card__wrapper">
                <div className="card__heading">Підтримай проєкт гривнею</div>
                <div className="card__description">
                    Для стабільної та безперебійної роботи Discord bot, сайту й
                    інших наших проєктів потрібні кошти, задонать на монобанку,
                    не жлобись
                </div>
                <div className="card__qrcode"></div>
                <div className="card__link">
                    <a
                        href="https://send.monobank.ua/jar/9y4BLqoxrV"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Mono Banka
                    </a>
                </div>
            </div>
        </div>
    );
};

Donate.propTypes = {};

export default Donate;
