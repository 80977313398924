export default {
    mixed: {
        default: "Значення не коректне",
        required: "Це поле обов'язкове до заповнення"
    },
    string: {
        length: "Значення містить рівно ${lenght} символів",
        min: "Кількість символів має бути не менше ${min}",
        max: "Кількість символів має бути не більше ${max}",
        email: "Email був введений некоректно",
        lowercase: "Тут має бути нижній регістр",
        uppercase: ""
    }
};
