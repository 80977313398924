import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
    Checkbox,
    FormControl,
    InputLabel,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Select
} from "@mui/material";

const SelectorWithCheckbox = ({
    options,
    defaultValue,
    label,
    name,
    disabled,
    fullWidth,
    variant,
    id,
    onChange,
    error,
    isSubmitting,
    className
}) => {
    const [selected, setSelected] = useState(
        defaultValue?.map((item) => item.name) || []
    );
    const [allSelected, setAllSelected] = useState(false);
    const selectAll = "All";
    const simpleOptions = options?.map((item) => item.name);

    useEffect(() => {
        const isAllSelected =
            simpleOptions?.length > 0 &&
            selected?.length === simpleOptions?.length;
        setAllSelected(isAllSelected);

        const fullValueSelected = [];
        selected?.forEach((item) => {
            options?.forEach((option) => {
                if (option.name === item) fullValueSelected.push(option.id);
            });
        });

        onChange({ id: name, value: fullValueSelected });
    }, [selected]);

    useEffect(() => {
        isSubmitting && setSelected([]);
    }, [isSubmitting]);

    const handleChange = (event) => {
        const value = event.target.value;

        if (value.some((item) => item === selectAll)) {
            setSelected(
                selected.length === simpleOptions.length
                    ? []
                    : simpleOptions.map((item) => item)
            );
        } else {
            setSelected(value);
        }
    };

    return (
        <FormControl fullWidth={fullWidth} className={className}>
            <InputLabel id={name} variant={variant} disabled={disabled}>
                {label}
            </InputLabel>
            <Select
                className="form__field select"
                id={id}
                labelId={name}
                multiple
                name={name}
                label={label}
                fullWidth={fullWidth}
                variant={variant}
                disabled={disabled}
                MenuProps={{ className: "select" }}
                value={selected}
                onChange={handleChange}
                renderValue={(selected) => selected.join(", ")}
                error={error}
            >
                <MenuItem value={selectAll}>
                    <ListItemIcon>
                        <Checkbox
                            checked={allSelected}
                            indeterminate={
                                selected.length > 0 &&
                                selected.length < options.length
                            }
                        />
                    </ListItemIcon>
                    <ListItemText primary="Вибрати всіх" />
                </MenuItem>
                {simpleOptions &&
                    simpleOptions.map((option) => (
                        <MenuItem key={option} value={option}>
                            <ListItemIcon>
                                <Checkbox
                                    checked={selected.some(
                                        (item) => item === option
                                    )}
                                />
                            </ListItemIcon>
                            <ListItemText primary={option} />
                        </MenuItem>
                    ))}
            </Select>
        </FormControl>
    );
};

SelectorWithCheckbox.propTypes = {
    options: PropTypes.array,
    defaultValue: PropTypes.array,
    label: PropTypes.string,
    name: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    id: PropTypes.string,
    fullWidth: PropTypes.bool,
    variant: PropTypes.string,
    error: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    className: PropTypes.string
};

export default SelectorWithCheckbox;
