import { Maintenance } from "components/Maintenance/Maintenance";
import React from "react";
// import PropTypes from "prop-types";

const News = (props) => {
    return <Maintenance />;
};

News.propTypes = {};

export default News;
