import yupLocale from "constants/yupLocale";
import * as Yup from "yup";

Yup.setLocale(yupLocale);

const UserUpdateSchema = Yup.object().shape({
    nickname: Yup.string().trim().min(3).max(30),
    role: Yup.string().trim()
});

export default UserUpdateSchema;
