import { Button, Typography } from "@mui/material";
import classNames from "classnames";
import routes from "constants/routes";
import React, { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
// import PropTypes from "prop-types";

import "./welcome.scss";

const CustomPortal = () => {
    return createPortal(
        <Link to={routes.outside.game} className="game__loader">
            <div className="shadow"></div>
            <div className="box"></div>
        </Link>,
        document.body
    );
};

const Welcome = (props) => {
    const { user } = useSelector((state) => state.auth);
    const canvasRef = useRef(null);
    const [activeAnimation, setActiveAnimation] = useState(true);

    const value = "Welcome to Boohlopark";

    const checkAndSaveActivity = () => {
        const lastActivity = localStorage.getItem("lastActivity");
        const now = Date.now();
        if (
            lastActivity &&
            Math.floor((now - lastActivity) / (1000 * 60)) >= 5
        ) {
            // the user was absent from the page longer (5 min)
            setActiveAnimation(true);
        } else {
            localStorage.setItem("lastActivity", Date.now());
            setActiveAnimation(false);
        }
    };

    // Canvas
    useEffect(() => {
        checkAndSaveActivity();

        document.body.className = "welcome";
        const canvas = canvasRef.current;
        const ctx = canvas.getContext("2d");
        const mouse = { x: 0, y: 0 };

        let particles = [];
        let amount = 0;
        let radius = 1;

        const colors = ["#0057B7", "#FFDD00"];

        let ww = (canvas.width = window.innerWidth);
        let wh = (canvas.height = window.innerHeight);

        function Particle(x, y) {
            this.x = Math.random() * ww;
            this.y = Math.random() * wh;
            this.dest = {
                x,
                y
            };
            this.r = Math.random() * 3 + 2;
            this.vx = (Math.random() - 0.5) * 20;
            this.vy = (Math.random() - 0.5) * 20;
            this.accX = 0;
            this.accY = 0;
            this.friction = Math.random() * 0.02 + 0.95;

            this.color = colors[Math.floor(Math.random() * 2)];
        }

        Particle.prototype.render = function () {
            this.accX = (this.dest.x - this.x) / 1000; // try 10
            this.accY = (this.dest.y - this.y) / 1000;
            this.vx += this.accX;
            this.vy += this.accY;
            this.vx *= this.friction;
            this.vy *= this.friction;

            this.x += this.vx;
            this.y += this.vy;

            ctx.fillStyle = this.color;
            ctx.beginPath();
            ctx.arc(this.x, this.y, this.r, Math.PI * 2, false);
            ctx.fill();

            const a = this.x - mouse.x;
            const b = this.y - mouse.y;

            const distance = Math.sqrt(a * a + b * b);
            if (distance < radius * 70) {
                this.accX = (this.x - mouse.x) / 100;
                this.accY = (this.y - mouse.y) / 100;
                this.vx += this.accX;
                this.vy += this.accY;
            }
        };

        function onMouseMove(e) {
            mouse.x = e.clientX;
            mouse.y = e.clientY;
        }

        function onTouchMove(e) {
            if (e.touches.length > 0) {
                mouse.x = e.touches[0].clientX;
                mouse.y = e.touches[0].clientY;
            }
        }

        function onTouchEnd(e) {
            mouse.x = -9999;
            mouse.y = -9999;
        }

        function initScene() {
            ww = canvas.width = window.innerWidth;
            wh = canvas.height = window.innerHeight;

            ctx.clearRect(0, 0, canvas.width, canvas.height);

            ctx.font = "bold " + ww / 12 + "px sans-serif";
            ctx.textAlign = "center";
            ctx.fillText(value, ww / 2, wh / 2);

            const data = ctx.getImageData(0, 0, ww, wh).data;
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.globalCompositeOperation = "screen";

            particles = [];
            // count of circle
            for (let i = 0; i < ww; i += Math.round(ww / 160)) {
                for (let j = 0; j < wh; j += Math.round(ww / 160)) {
                    if (data[(i + j * ww) * 4 + 3] > 150) {
                        particles.push(new Particle(i, j));
                    }
                }
            }
            amount = particles.length;
        }

        function onMouseClick() {
            radius++;
            if (radius === 5) {
                radius = 0;
            }
        }

        function render(a) {
            requestAnimationFrame(render);
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            for (let i = 0; i < amount; i++) {
                particles[i].render();
            }
        }

        window.addEventListener("resize", initScene);
        window.addEventListener("mousemove", onMouseMove);
        window.addEventListener("touchmove", onTouchMove);
        // window.addEventListener("click", onMouseClick);
        window.addEventListener("touchend", onTouchEnd);
        initScene();
        requestAnimationFrame(render);

        return () => {
            window.removeEventListener("resize", initScene);
            window.removeEventListener("mousemove", onMouseMove);
            window.removeEventListener("touchmove", onTouchMove);
            window.removeEventListener("click", onMouseClick);
            window.removeEventListener("touchend", onTouchEnd);
        };
    }, []);

    return (
        <div className="welcome-page">
            <div className="welcome__wrapper">
                <canvas id="scene" ref={canvasRef}></canvas>
                <CustomPortal />
                <div className="welcome__content">
                    <Typography
                        className={classNames("welcome__title", {
                            exist: !activeAnimation
                        })}
                        variant="h2"
                        align="center"
                        sx={{ flexGrow: 1 }}
                    >
                        Привіт, {user.name}
                    </Typography>
                    <Typography
                        variant="body1"
                        className={classNames("welcome__text", {
                            exist: !activeAnimation
                        })}
                    >
                        Ви користуєтеся обмеженою версією сайту, щоб отримати
                        доступ до повної версії, зверніться до адміністратора
                        Discord серверу
                    </Typography>
                    <div
                        className={classNames("welcome__buttons", {
                            exist: !activeAnimation
                        })}
                    >
                        <Button
                            className="welcome__button game"
                            variant="outlined"
                            color="secondary"
                            disabled
                        >
                            Coming soon
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

Welcome.propTypes = {};

export default Welcome;
