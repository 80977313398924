import { Container } from "@mui/material";
import Header from "components/Header/Header";
import React from "react";
import { Outlet } from "react-router-dom";
import "./outsidelayout.scss";

export const OutsideLayout = () => {
    return (
        <div className="outside-layout">
            <div className="layout__wrapper">
                <div className="layout__header">
                    <Header isOutsidePage />
                </div>
                <div className="layout__content">
                    <Container className="layout__container" maxWidth="xl">
                        <Outlet />
                    </Container>
                </div>
            </div>
        </div>
    );
};
