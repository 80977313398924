const isNotProductionMode = process.env.NODE_ENV !== "production";

/* eslint-disabled no-console */
const Logger = {
    log(...args) {
        isNotProductionMode && console.log(...args);
    },
    error(...args) {
        isNotProductionMode && console.error(...args);
    },
    info(...args) {
        isNotProductionMode && console.log(...args);
    },
    warn(...args) {
        isNotProductionMode && console.warn(...args);
    }
};
/* eslint-disabled no-console */

export default Logger;
