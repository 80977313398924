import { useSnackbar } from "notistack";
import React from "react";
export let VariantType;
(function (VariantType) {
    VariantType.default = "default";
    VariantType.error = "error";
    VariantType.success = "success";
    VariantType.warning = "warning";
    VariantType.info = "info";
})(VariantType || (VariantType = {}));
const InnerSnackbarUtilsConfigurator = (props) => {
    // eslint-disable-next-line react/prop-types
    props.setUseSnackbarRef(useSnackbar());
    return null;
};
let useSnackbarRef;
const setUseSnackbarRef = (useSnackbarRefProp) => {
    useSnackbarRef = useSnackbarRefProp;
};
export const SnackbarUtilsConfigurator = (props) => {
    return React.createElement(
        InnerSnackbarUtilsConfigurator,
        { setUseSnackbarRef },
        // eslint-disable-next-line react/prop-types
        props.children
    );
};
// sets a default length for all Snack messages
const defaultSnackMessageLength = 1000;
const trimMessage = (msg, length = defaultSnackMessageLength) => {
    return msg.substring(0, length);
};
export default {
    success(msg, options = {}) {
        this.toast(
            trimMessage(msg),
            Object.assign(Object.assign({}, options), {
                variant: VariantType.success
            })
        );
    },
    warning(msg, options = {}) {
        this.toast(
            trimMessage(msg),
            Object.assign(Object.assign({}, options), {
                variant: VariantType.warning
            })
        );
    },
    info(msg, options = {}) {
        this.toast(
            trimMessage(msg),
            Object.assign(Object.assign({}, options), {
                variant: VariantType.info
            })
        );
    },
    error(msg, options = {}) {
        this.toast(
            trimMessage(msg),
            Object.assign(Object.assign({}, options), {
                variant: VariantType.error
            })
        );
    },
    toast(msg, options = {}) {
        useSnackbarRef.enqueueSnackbar(msg, options);
    },
    loading(msg, options) {
        this.toast(
            trimMessage(msg),
            Object.assign(Object.assign({}, options), {
                variant: "info",
                persist: true,
                content: (id, _message) => {
                    return React.createElement("div", {
                        key: id,
                        style: { width: "100%" }
                    });
                }
            })
        );
    }
};
