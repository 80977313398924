import { Breadcrumbs } from "@mui/material";
import { breadcrumbRoutes } from "constants/breadcrumbRoutes";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import useBreadcrumbs from "use-react-router-breadcrumbs";
// import PropTypes from "prop-types";
import "./breadcrumbs.scss";

const Breadcrumb = (props) => {
    const { pathname } = useLocation();
    const [render, setRender] = useState(true);

    useEffect(() => {
        if (pathname === "/") {
            setRender(false);
        } else {
            setRender(true);
        }

        const pageName = pathname.substring(1).replaceAll("/", "-");

        if (pageName === "") {
            document.body.className = "home";
        } else {
            document.body.className = pageName;
        }

        return () => (document.body.className = "");
    }, [pathname]);

    const breadcrumbs = useBreadcrumbs(breadcrumbRoutes, {
        excludePaths: ["catalogs"]
    });

    return (
        <Breadcrumbs
            className="breadcrumbs"
            separator="/"
            aria-label="breadcrumb"
        >
            {render &&
                breadcrumbs.map(({ match, breadcrumb }) => (
                    <Link
                        key={match.pathname}
                        className="breadcrumbs__link"
                        to={match.pathname}
                    >
                        {breadcrumb}
                    </Link>
                ))}
        </Breadcrumbs>
    );
};

Breadcrumb.propTypes = {};

export default Breadcrumb;
