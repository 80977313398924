import React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
// import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import PropTypes from "prop-types";

const BasicSelector = ({
    fullWidth,
    className,
    name,
    value,
    label,
    id,
    onChange,
    options,
    error,
    helpText,
    disabled
}) => {
    const handleChange = (event) => {
        onChange(event.target.value);
    };

    return (
        <FormControl
            fullWidth={fullWidth}
            className={className}
            variant="standard"
            disabled={disabled}
        >
            <InputLabel id={`label-${id}`} variant="filled">
                {label}
            </InputLabel>
            <Select
                variant="filled"
                className="form__field select"
                fullWidth={fullWidth}
                labelId={`label-${id}`}
                id={id}
                name={name}
                value={value}
                error={error}
                label={label}
                onChange={handleChange}
            >
                {options.map((item, index) => (
                    <MenuItem key={item.id || index} value={item.value}>
                        {item.value}
                    </MenuItem>
                ))}
            </Select>
            {/* <FormHelperText>Disabled</FormHelperText> */}
        </FormControl>
    );
};

BasicSelector.propTypes = {
    label: PropTypes.string,
    id: PropTypes.string,
    fullWidth: PropTypes.bool,
    className: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    options: PropTypes.array,
    error: PropTypes.bool,
    helpText: PropTypes.string,
    disabled: PropTypes.bool
};

export default BasicSelector;
