import { Avatar } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
// import PropTypes from "prop-types";

import "./profile.scss";

const Profile = (props) => {
    const { user } = useSelector((state) => state.auth);
    return (
        <div className="page-profile">
            <div className="info__wrapper">
                <div className="info__top"></div>
                <div className="info_bottom">
                    <div className="info__general"></div>
                    <div className="info__user">
                        <div className="user__image">
                            <Avatar alt={user.name} src={user.picture} />
                        </div>
                        <div className="user__name">{user.name}</div>
                        <div className="user__email">{user.email}</div>
                    </div>
                    <div className="info__socials"></div>
                </div>
            </div>
        </div>
    );
};

Profile.propTypes = {};

export default Profile;
