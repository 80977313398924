import yupLocale from "constants/yupLocale";
import * as Yup from "yup";

Yup.setLocale(yupLocale);

const AddEventSchema = Yup.object().shape({
    name: Yup.string().trim().min(3).max(30).required(),
    location: Yup.string().trim().min(3).max(30),
    members: Yup.array(),
    list: Yup.array(),
    isCreateChat: Yup.boolean(),
    dateEvent: Yup.string()
});

export default AddEventSchema;
