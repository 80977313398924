import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import RemoveRedEyeRoundedIcon from "@mui/icons-material/RemoveRedEyeRounded";

import PropTypes from "prop-types";
import { Button, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import routes from "constants/routes";
import { getPeriod } from "utils/utils";
import messages from "constants/messages";
import { DateObject } from "react-multi-date-picker";
import { defaultDateFormat } from "constants/settings";

import "./tables.scss";
import classNames from "classnames";
import { sortBy } from "lodash";

const TableSticky = ({ events }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [rows, setRows] = useState([]);

    const columns = [
        {
            id: "name",
            label: messages.events.table.name,
            minWidth: 100,
            align: "left"
        },
        {
            id: "location",
            label: messages.events.table.location,
            minWidth: 100,
            align: "center"
        },
        {
            id: "members",
            label: messages.events.table.members,
            minWidth: 100,
            align: "center"
        },
        {
            id: "date",
            label: messages.events.table.date,
            minWidth: 100,
            align: "center"
        },
        {
            id: "isCreateChat",
            label: messages.events.table.chat,
            minWidth: 100,
            align: "center"
        },
        { id: "actions", label: "", minWidth: 100, align: "center" }
    ];

    const createChatIcon = (isCreateChat) => {
        const icon = isCreateChat ? (
            <DoneRoundedIcon color="success" />
        ) : (
            <ClearRoundedIcon color="error" />
        );
        return icon;
    };

    const createViewButton = (id, breadcrumb, completed) => {
        return (
            <Button
                component={Link}
                to={routes.events.edit.root(id)}
                state={{ breadcrumb, edit: true }}
                className={classNames({
                    "table-button-disabled": completed
                })}
            >
                <RemoveRedEyeRoundedIcon />
            </Button>
        );
    };

    const createHumanDate = (dates, completed) => {
        const pediod = getPeriod(dates[0], dates[dates.length - 1]);
        const oneDay = new DateObject(dates).format(defaultDateFormat);
        if (completed)
            return (
                // use oneDay if params only one day
                <Tooltip title={oneDay} arrow>
                    <span className="table-text-completed">
                        {messages.events.table.completed}
                    </span>
                </Tooltip>
            );

        if (!dates) return <ClearRoundedIcon color="error" />;

        if (typeof dates === "object") {
            return pediod;
        } else {
            return oneDay;
        }
    };

    useEffect(() => {
        const tmpArr = [];
        events.forEach(
            ({
                id,
                name,
                location,
                members,
                dateEvent,
                isCreateChat,
                completed
            }) => {
                tmpArr.push({
                    id,
                    name,
                    completed,
                    location: location || <ClearRoundedIcon color="error" />,
                    members: members?.length || 0,
                    date: createHumanDate(dateEvent, completed),
                    isCreateChat: createChatIcon(isCreateChat),
                    actions: createViewButton(id, name, completed)
                });
            }
        );

        const sortArr = sortBy(tmpArr, ["completed"]);
        setRows(sortArr);
    }, [events]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return rows.length ? (
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: 440 }} className="table-container">
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows
                            .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                            )
                            .map((row) => {
                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={row.id}
                                        className={classNames({
                                            "table-row-disabled": row.completed
                                        })}
                                    >
                                        {columns.map((column) => {
                                            const value = row[column.id];
                                            return (
                                                <TableCell
                                                    key={column.id}
                                                    align={column.align}
                                                >
                                                    {column.format &&
                                                    typeof value === "number"
                                                        ? column.format(value)
                                                        : value}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    ) : (
        messages.events.emptyList
    );
};

TableSticky.propTypes = {
    events: PropTypes.array
};

export default TableSticky;
