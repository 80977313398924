import React, { useState } from "react";

import messages from "constants/messages";
import { useGetUsersQuery } from "store/users/userApiSlice";
import { Button, Tooltip } from "@mui/material";
// import PropTypes from "prop-types";

import "./admin.scss";
import LoadingComponent from "components/Loadings/LoadingComponent";
import UpdateUserDialog from "./UpdateUserDialog";

const AdminPanel = (props) => {
    const { data: userList, isLoading } = useGetUsersQuery();
    const [openModal, setOpenModal] = useState(false);
    const [activeUser, setActiveUser] = useState(null);

    const showModal = (open, user) => {
        setOpenModal(open);

        setActiveUser(user || null);
    };

    return (
        <div className="page-adminpanel">
            <div className="admin__wrapper">
                {/* <div className="admin__filter"></div> */}
                <div className="admin__lists">
                    <LoadingComponent loading={isLoading}>
                        <div className="user__list">
                            {userList?.map((user) => (
                                <div className="user__item" key={user.id}>
                                    <div className="user__name">
                                        {user.name}
                                        {user?.nickname && (
                                            <Tooltip title="Nickname" arrow>
                                                <span className="user__nickname">
                                                    &nbsp; [{user?.nickname}]
                                                </span>
                                            </Tooltip>
                                        )}
                                    </div>
                                    <div className="user__email">
                                        {user.email}
                                    </div>
                                    <div className={`user__role ${user.role}`}>
                                        {user.role}
                                    </div>
                                    <div className="user__actions">
                                        <Button
                                            className="user__button"
                                            color="primary"
                                            variant="outlined"
                                            onClick={() =>
                                                showModal(true, user)
                                            }
                                        >
                                            {messages.general.buttons.edit}
                                        </Button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </LoadingComponent>
                </div>
                {openModal && (
                    <UpdateUserDialog
                        initialValues={activeUser}
                        openModal={openModal}
                        setOpenModal={setOpenModal}
                    />
                )}
            </div>
        </div>
    );
};

AdminPanel.propTypes = {};

export default AdminPanel;
