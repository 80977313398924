import React from "react";
import routes from "./routes";

import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
import NewspaperRoundedIcon from "@mui/icons-material/NewspaperRounded";
import MovieFilterRoundedIcon from "@mui/icons-material/MovieFilterRounded";
import MenuBookRoundedIcon from "@mui/icons-material/MenuBookRounded";
import CameraRoundedIcon from "@mui/icons-material/CameraRounded";
import CelebrationRoundedIcon from "@mui/icons-material/CelebrationRounded";
import SavingsRoundedIcon from "@mui/icons-material/SavingsRounded";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";

export const Menu = [
    {
        name: "Dashboard",
        url: routes.dashboard,
        icon: <DashboardRoundedIcon />
    },
    {
        name: "News",
        url: routes.news,
        icon: <NewspaperRoundedIcon />
    },
    {
        name: "Movies",
        url: routes.catalogs.movies,
        icon: <MovieFilterRoundedIcon />
    },
    {
        name: "Books",
        url: routes.catalogs.books,
        icon: <MenuBookRoundedIcon />
    },
    {
        name: "Photos",
        url: routes.photos,
        icon: <CameraRoundedIcon />
    },
    {
        name: "Events",
        url: routes.events.root,
        icon: <CelebrationRoundedIcon />
    },
    {
        name: "Calendar",
        url: routes.calendar,
        icon: <CalendarMonthRoundedIcon />
    },
    {
        name: "Donate",
        url: routes.donate,
        icon: <SavingsRoundedIcon />
    }
];
