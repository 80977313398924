import React from "react";
import "./style.scss";

export const Maintenance = () => {
    const random = Math.floor(Math.random() * (99 - 5 + 1)) + 5;
    return (
        <div className="page-maintenance">
            <div className="bilmm izle">
                <div className="hareket">
                    █ █ █{" "}
                    <span style={{ color: "var(--error)" }}>
                        █ █ █ █ █ █ █ █ █ █{" "}
                    </span>
                    {random}%
                    <br />
                    <br />
                    &gt; Ми працюємо
                    <br />
                    &gt; Незабаром сторінка буде готова
                    <br /> &gt; www.boohlopark.com/ <span id="imlec">█</span>
                </div>
            </div>
        </div>
    );
};
