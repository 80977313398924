import { apiSlice } from "api/apiSlice";

export const eventApiSlice = apiSlice.injectEndpoints({
    endpoints: (build) => ({
        getEvents: build.query({
            query: () => ({
                url: "events"
            }),
            providesTags: ["Events"]
        }),
        getSingleEvent: build.query({
            query: (eventId) => ({
                url: `events/${eventId}`
            })
        }),
        addEvent: build.mutation({
            query: (event) => ({
                url: `events`,
                method: "POST",
                body: event
            }),
            invalidatesTags: ["Events"]
        }),
        updateEvent: build.mutation({
            query: ({ id, event }) => ({
                url: `events/${id}`,
                method: "PUT",
                body: event
            }),
            invalidatesTags: ["Events"]
        })
    })
});

export const {
    useGetEventsQuery,
    useLazyGetSingleEventQuery,
    useAddEventMutation,
    useUpdateEventMutation
} = eventApiSlice;
