import React from "react";
import PropTypes from "prop-types";
import { FormControl } from "@mui/material";

const CheckBoxWrapper = ({ children, fullWidth, className, disabled }) => {
    return (
        <FormControl
            fullWidth={fullWidth}
            className={className}
            disabled={disabled}
        >
            <div className="form__divider" style={{ margin: "0 0 10px 0" }}>
                <hr />
            </div>
            {children}
            <div className="form__divider" style={{ margin: "10px 0 0" }}>
                <hr />
            </div>
        </FormControl>
    );
};

CheckBoxWrapper.propTypes = {
    children: PropTypes.object.isRequired,
    fullWidth: PropTypes.bool,
    disabled: PropTypes.bool,
    className: PropTypes.string
};

export default CheckBoxWrapper;
