import React, { useEffect, useState } from "react";
// import PropTypes from "prop-types";
import DashboardRouter from "./DashboardRouter";
import OutsideRouter from "./OutsideRouter";
import { useDispatch, useSelector } from "react-redux";
import { checkForAdmin, checkForUser } from "utils/utils";
import LoadingComponent from "components/Loadings/LoadingComponent";
import { setUser } from "store/auth/authSlice";
import { useGetUserQuery } from "store/users/userApiSlice";

// Level 2
const RegisteredSwitch = (props) => {
    const { user } = useSelector((state) => state.auth);
    const [isRegistered, setIsRegistered] = useState(false);
    useEffect(() => {
        if (user) {
            if (checkForAdmin(user.role) || checkForUser(user.role)) {
                setIsRegistered(true);
            } else {
                setIsRegistered(false);
            }
        }
    }, [user]);
    const dispatch = useDispatch();

    const { data, isSuccess } = useGetUserQuery(user.uid);
    const userData = data?.user;
    const role = userData?.role;

    const isAdmin = checkForAdmin(role);

    useEffect(() => {
        // protection against role change in local storage or in the Redux
        data && dispatch(setUser({ user: data.user }));
    }, [data]);

    return (
        isSuccess &&
        (isRegistered ? (
            <LoadingComponent coverPage minTime={1200}>
                <DashboardRouter isAdmin={isAdmin} />
            </LoadingComponent>
        ) : (
            <OutsideRouter />
        ))
    );
};

RegisteredSwitch.propTypes = {};

export default RegisteredSwitch;
