import React from "react";
import { OutsideLayout } from "components/OutsideLayout/OutsideLayout";
import routes from "constants/routes";
import { Route, Routes } from "react-router-dom";
import ErrorPage from "pages/error/ErrorPage";
// import PropTypes from "prop-types";
import Welcome from "pages/welcome/Welcome";
import Game from "pages/game/Game";

// Level 3
const OutsideRouter = () => {
    return (
        <Routes>
            <Route path={routes.home} element={<OutsideLayout />}>
                {/* TODO add page */}
                <Route index element={<Welcome />} />
                <Route path={routes.outside.game} element={<Game />} />
                <Route path={routes.error} element={<ErrorPage />} />
            </Route>
        </Routes>
    );
};

OutsideRouter.propTypes = {};

export default OutsideRouter;
