import React from "react";

import "./logo.scss";

const AnimateLogo = (props) => {
    return (
        <div className="animate-wrapper-logo">
            <span>Boohlopark</span>
            <span>Boohlopark</span>
        </div>
    );
};

export default AnimateLogo;
