import React from "react";
import { Provider } from "react-redux";
import store, { persistor } from "store";

import "assets/styles/main.scss";
import { BrowserRouter } from "react-router-dom";
import AppRouter from "routes/AppRouter";
import { PersistGate } from "redux-persist/integration/react";
import { ThemeProvider } from "@mui/material";
import { SnackbarProvider } from "notistack";
import { theme } from "constants/theme";
import { SnackbarUtilsConfigurator } from "components/Snack/Snack";

const App = () => {
    return (
        <ThemeProvider theme={theme}>
            <Provider store={store}>
                <PersistGate persistor={persistor}>
                    <SnackbarProvider
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "right"
                        }}
                        preventDuplicate
                        autoHideDuration={3000}
                        maxSnack={2}
                    >
                        <SnackbarUtilsConfigurator />
                        <BrowserRouter>
                            <AppRouter />
                        </BrowserRouter>
                    </SnackbarProvider>
                </PersistGate>
            </Provider>
        </ThemeProvider>
    );
};

export default App;
