import React, { useCallback, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { CircularProgress } from "@mui/material";
import { debounce } from "lodash";

import "./loading.scss";
import classNames from "classnames";
import beerWaves from "assets/images/loading/layered-waves-haikei.png";

const LoadingComponent = ({ loading, children, minTime, coverPage }) => {
    const [loaded, setLoaded] = useState(true);

    const debounceHandler = useCallback(
        debounce(() => setLoaded(false), minTime),
        []
    );

    useEffect(() => {
        !loading && debounceHandler();
    }, [loading]);

    return loaded ? (
        <div
            className={classNames("loading", {
                "reload-page": coverPage
            })}
        >
            {coverPage ? (
                <div
                    className="beer-loading"
                    style={{
                        backgroundImage: `url(${process.env.REACT_APP_PUBLIC_URL}${beerWaves})`
                    }}
                ></div>
            ) : (
                <CircularProgress color="primary" />
            )}
        </div>
    ) : (
        children
    );
};

LoadingComponent.defaultProps = {
    minTime: 0,
    coverPage: false
};

LoadingComponent.propTypes = {
    loading: PropTypes.bool,
    children: PropTypes.object,
    minTime: PropTypes.number,
    coverPage: PropTypes.bool
};

export default LoadingComponent;
