import React from "react";
// import PropTypes from "prop-types";
import { Maintenance } from "components/Maintenance/Maintenance";

const Game = (props) => {
    return <Maintenance />;
};

Game.propTypes = {};

export default Game;
