import { Button } from "@mui/material";
import routes from "constants/routes";
import React from "react";
import { Link, useNavigate } from "react-router-dom";

import "./error.scss";

const ErrorPage = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(-1);
    };
    return (
        <div className="page-error">
            <div className="page-error__text">404</div>
            <div className="page-error__container">
                <div className="caveman">
                    <div className="leg">
                        <div className="foot">
                            <div className="fingers"></div>
                        </div>
                    </div>
                    <div className="leg">
                        <div className="foot">
                            <div className="fingers"></div>
                        </div>
                    </div>
                    <div className="shape">
                        <div className="circle"></div>
                        <div className="circle"></div>
                    </div>
                    <div className="head">
                        <div className="eye">
                            <div className="nose"></div>
                        </div>
                        <div className="mouth"></div>
                    </div>
                    <div className="arm-right">
                        <div className="club"></div>
                    </div>
                </div>
                <div className="caveman">
                    <div className="leg">
                        <div className="foot">
                            <div className="fingers"></div>
                        </div>
                    </div>
                    <div className="leg">
                        <div className="foot">
                            <div className="fingers"></div>
                        </div>
                    </div>
                    <div className="shape">
                        <div className="circle"></div>
                        <div className="circle"></div>
                    </div>
                    <div className="head">
                        <div className="eye">
                            <div className="nose"></div>
                        </div>
                        <div className="mouth"></div>
                    </div>
                    <div className="arm-right">
                        <div className="club"></div>
                    </div>
                </div>
            </div>
            <div className="page-error__wrapper">
                <p>Халепа, такої сторінки не існує</p>
                <div className="page-error__buttons">
                    <Button
                        variant="text"
                        size="large"
                        disableElevation
                        component={Link}
                        to={routes.dashboard}
                    >
                        На головну
                    </Button>
                    <Button
                        color="secondary"
                        variant="text"
                        size="large"
                        style={{ marginLeft: "20px" }}
                        disableElevation
                        onClick={handleClick}
                    >
                        Назад
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ErrorPage;
