import React from "react";

import LoginPage from "pages/login/LoginPage";
import { Route, Routes } from "react-router-dom";
import { PrivateRoute, AuthRoute } from "./ProtectedRouter";

import routes from "constants/routes";
import RegisteredSwitch from "./RegisteredSwitch";

// Level 1
const AppRouter = () => {
    return (
        <Routes>
            <Route
                index
                path={routes.home}
                element={
                    <PrivateRoute>
                        <RegisteredSwitch />
                    </PrivateRoute>
                }
            />
            <Route
                path={routes.login}
                element={
                    <AuthRoute>
                        <LoginPage />
                    </AuthRoute>
                }
            />
        </Routes>
    );
};

export default AppRouter;
